import React from "react";
import { graphql } from "gatsby";
import List from "./List";
import { makeStyles } from "@material-ui/core/styles";
import { grey } from "@material-ui/core/colors";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";

const useStyles = makeStyles((theme) => ({
  tagTitle: {
    "& svg": {
      marginRight: theme.spacing(2),
      verticalAlign: "middle",
      fill: grey["400"],
      width: "36px",
      height: "36px",
    },
  },
  subtitle: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    textAlign: "center",
    color: grey["600"],
    backgroundColor: grey["300"],
  },
  header: {
    "& > *": {
      marginBottom: theme.spacing(0),
      marginTop: 0,
      "&:last-child": {
        marginTop: theme.spacing(5),
      },
    },
  },
}));

function ListTag({ data, pageContext }) {
  const classes = useStyles();
  return (
    <>
      <Container maxWidth="lg">
        <header className={classes.header}>
          <Typography variant="h1" component="h1" className={classes.tagTitle}>
            <LocalOfferIcon />
            {pageContext.title}
          </Typography>
        </header>
      </Container>
      {data.allNodeArticle && data.allNodeArticle.edges.length > 0 && (
        <>
          <h3 className={classes.subtitle}>News</h3>
          <List nodes={data.allNodeArticle} pageContext={pageContext} format="news" />
        </>
      )}
    </>
  );
}

export default ListTag;

export const query = graphql`
  query tagsQuery($id: String!) {
    allMarkdownRemark(filter: { frontmatter: { slug: { eq: "tags" } } }) {
      nodes {
        frontmatter {
          title
          slug
        }
      }
    }
    allNodeArticle(
      filter: { relationships: { field_tags: { elemMatch: { drupal_id: { eq: $id } } } } }
    ) {
      edges {
        node {
          drupal_id
          field_date(formatString: "MMMM Do, YYYY")
          title
          body {
            processed
            summary
          }
          fields {
            slug
          }
          relationships {
            field_tags {
              drupal_id
              name
            }
          }
        }
      }
    }
  }
`;
